

.CoreOffering{
    margin-top: 80px; 
}

.CoreOffering-Head{
    font-size: var(--headerMini-font-size);
    position: relative;
    font-weight: var(--Primary-font-weight);
    max-width: 314px;
    margin: 0px auto;
    letter-spacing: 2px;
    text-align: center;
    cursor: default;
    margin-bottom: 40px;
}

.CoreOffering-Head::before{
    content: '';
    position: absolute;
    bottom: -5px;
    left: 8px;
    transition: all 250ms;
    width: 60px;
    height: 2px;
    background-color: black;
}

.CoreOffering-Head:hover::before{
    width: 100px;
}


.Core-off-Card-Container{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
}

.Core-off-Card-Container div{
    flex-grow: 1;
}

@media all and (max-width:500px) {
    .CoreOffering-Head{
        font-size: 26px;
    }

    .CoreOffering-Head::before{
        display: none;
    }
}

@media all and (max-width:1200px) {
    .Core-off-Card-Container {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media all and (max-width:1000px){
    .Core-off-Card-Container {
            grid-template-columns: repeat(2, 1fr);  
        }
}
@media all and (max-width:700px){
    .CoreOffering-Head::before{
          display: none;
    }
}
@media all and (max-width:768px){
    .Core-off-Card-Container {
            grid-template-columns: repeat(1, 1fr);  
        }
}


