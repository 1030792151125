

.Product-container{
    padding: 0px 72px;  
    padding-top: 80px;
    margin-top: 26px;
}

.Product{
    margin-bottom: 40px;
    color: var(--brand-font-color);
        font-size: var(--header-font-size);
        font-weight: var(--Primary-font-weight);
        position: relative;
        width: 190px;
        cursor: default;
}

.Product::before {
    content: '';
    width: 60px;
    height: 2px;
    background-color: var(--brand-font-color);
    transition: all 250ms;
    position: absolute;
    bottom: -4px;
    left: 5px;
}


.Product:hover::before {
    width: 100px;
}
.product-inside-container{
    display: flex;
    gap: 50px;
}

.product-content h2{
    font-size: var(--secondary-font-size);
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.product-content p{
    font-size: var(--secondary-ParaFont-size);
    display: flex;
     gap: 10px;
    margin-top: 20px;
    font-weight: var(--external-font-weight);
}

.product-img-container{
    width: 50%;
}

.product-content{
    width: 50%;
}

.Product-img{
    width: 100%;
    /* height: 100%; */
}


.BrandShowcase{
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
}

.BrandShowcase h1{
    font-size: var(--headerMini-font-size);
    position: relative;
    font-weight: var(--Primary-font-weight);
    margin: 0px auto;
    width: 600px;
    letter-spacing: 2px;
    /* border-bottom: 2px solid var(--brand-font-color);/ */
    background: linear-gradient(to right, #4051B5, #C39828);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    text-align: center;
}

.BrandShowcase h1::before{
    content: '';
    width: 60px;
    height: 2px;
    left: 4px;
    position: absolute;
    transition: all 500ms;
    background-color: #4051B5;
    bottom: -3px;
}

.BrandShowcase h1:hover::before{
    width: 100px;
}

.oi-creation{
    position: relative;
    max-width:402px;
}

.Gear-Icons{
    z-index: -2;
    position: absolute;
    top: -29px;
    right: -64px;
    color: var(--brand-font-color);
    font-size: 50px;
    transform: rotate(96deg);
    /* color: gray; */
}

.oi-creation h1{
    width: 100%;
}

.down-arrow {
    font-size: 50px;
    color: var(--brand-font-color);
    height: 50px;
    animation: bounce 2s infinite;
}

@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-20px);
    }

    60% {
        transform: translateY(-10px);
    }
}

.ProductLogo{
    border-radius: 50%;
    width: 100px;
}

@media all and (max-width:500px){
    .Product-container {
       padding: 0px 30px; 
       padding-top: 80px;
    }

    
    .product-content h2{
        font-size: var(--title-Font-size);
    }
}

@media all and (max-width:500px){

       .Product {
            font-size: var(--title-Font-size);
        }
    
        .product-content p {
            font-size: var(--Least-font-size);
        }
}

@media all and (max-width:768px){
    .product-inside-container {
            flex-direction: column-reverse;
    }

    .product-img-container {
        width: 100%;
    }


    .product-content {
        width: 100%;
    }

}