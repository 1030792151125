*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body{
  font-family: "Wix Madefor Text", sans-serif;
  background-color: var(--brand-bg-color) !important;
}

h1,h2,h3,h4,h5,h6 {
  font-size: 1em !;
  font-weight: normal;
  margin: 0;
  padding: 0;
  line-height: 1.5;
}