
.StrategicBM{
    margin-top: 80px;
}

.StrategicBM h1{
    font-size: var(--headerMini-font-size);
    cursor: default;
    position: relative;
    font-weight: var(--Primary-font-weight);
    margin: 0px auto;
    max-width: 522px;
    /* border: 1px solid; */
    letter-spacing: 2px;
    text-align: center;
}

.StrategicBM h1::before {
    content: '';
    width: 60px;
    height: 2px;
    background-color: black;
    transition: all 250ms;
    position: absolute;
    bottom: -6px;
    left:2px;
}

.StrategicBM h1:hover::before {
    width: 100px;
}


.StrategicBM h3{
    margin-top: 50px;
    color: var(--brand-font-color);
    font-size: var(--headerMini-font-size);
    letter-spacing: 0.6px;
}

.StrategicBM p{
    margin-top: 20px;
    display: flex;
    gap: 10px;
    font-size: var(--mini-ParaFont-size);
    font-weight: 400;
    letter-spacing: 0.6px;
}

.Square-Img{
    width: 15px;
    margin-top: -1px;
    padding-right: 5px;
    transform: rotate(45deg);
}

@media all and (max-width:700px){
    .StrategicBM h1::before {
            display: none;
        }
}

@media all and (max-width:500px){

    .StrategicBM h1{
        font-size: 26px;
    }


    .StrategicBM h3{
        font-size: var(--card-headFont-size);
    }

    .StrategicBM p{
        font-size: var(--Least-font-size);
    }

    
}

