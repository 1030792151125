

.carousel-control-prev-icon,
.carousel-control-next-icon {
    background-image: none;
    /* Remove default icon */
}

.carousel-control-prev-icon {
    background: url('../../../Assets/arrow (3).png') no-repeat center center;
    background-size: 100% 100%;
}

.carousel-control-next-icon {
    background: url('../../../Assets/arrow (2).png') no-repeat center center;
    background-size: 100% 100%;
}

.Carousel-img{
    border-radius: 50%;
    display: flex;
    width: 200px;
    margin: auto;
}

.carousel{
        max-width: 800px;
        /* border: 1px solid ; */
        margin: auto;
        margin-top: 70px;
}

.carousel-indicators .active{
    background-color: var(--brand-font-color);
}

.carousel-control-next,
.carousel-control-prev {
    position: absolute;
    top: 50%;
    transform: translatey(-50%);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    padding: 0;
    /* color:; */
    text-align: center;
    background: 0 0;
    height: 100px;
    border: 0;
    opacity: .5;
    transition: opacity .15s ease;
}

.carousel-indicators {
    bottom: -50px;
}

.Carousel-Title{
    text-align: center !important;
    color: var(--brand-font-color);
    margin-top: 50px;
}

.Carousel-Desc{
    text-align: center;
    margin-top: 10px;
    text-align: center;
    margin-top: 10px;
    max-width: 600px;
    margin: auto;
    margin-top: 10px;
}

@media all and (max-width:500px){
    .carousel-control-next,
        .carousel-control-prev{
            right: -13px;
            top: 42%;
            background-color: white !important;
            width: 40px !important;
            height: 40px !important;
            border-radius: 50%;
        }

                .carousel-control-prev {
                    left: -13px !important;
                }

        .carousel-control-next-icon,
        .carousel-control-prev-icon{
            width: 16px !important;
            height: 16px !important;
        }
     .Carousel-img{
        max-width: 150px;
     }   
}

@media all and (max-width:600px){
    .carousel-control-next,
        .carousel-control-prev{
            top: 42%;
        }
}