

.About-us-banner-img{
    width: 100%;
    height: 700px;
    object-fit: cover;
    filter: brightness(20%);
}

.About-us{
    position: relative;
    padding-top: 60px;
}

.About-Us-head{
    position: absolute;
    top: 90px;
    /* padding: 0px 72px; */
    left: 72px;
    /* border: 1px solid red; */
    color: var(--brand-font-color);
    font-size: var(--header-font-size);
    font-weight: var(--Primary-font-weight);
    max-width: 322px;
    cursor: default;
}

.About-Us-head::before {
    content: '';
    width: 60px;
    height: 2px;
    background-color: var(--brand-font-color);
    transition: all 250ms;
    position: absolute;
    bottom: -2px;
    left: 2px;
}


.About-Us-head:hover::before {
    width: 100px;
}

.About-Content{
    position: absolute;
    color: white;
    width: 100%;
    padding: 0px 72px;
    top: 50%;
    left: 50%;
    transform: translate(-50% ,-50%);
}

.Content-wrapper{
    position: absolute;
    top: 50%;
    left: 50%;
}


.About-content-top{
    margin-bottom: 50px;
}

.About-content-top h2{
    font-size: var(--headerMini-font-size);
    text-align: center;
    letter-spacing: 3px;
    margin-bottom: 20px;
    color: var(--brand-font-color);
}

.About-content-top p{
    font-size: var(--secondary-ParaFont-size);
    text-align: center;
    color: white;
}

.About-content-bottom h2{
    font-size: var(--headerMini-font-size);
    text-align: center;
    letter-spacing: 3px;
    margin-bottom: 20px;
    color: var(--brand-font-color);
}

.About-content-bottom p{
    text-align: center;
    font-size: var(--secondary-ParaFont-size);
        color: white;
}

@media all and (max-width:1070px){
    .About-Content {
            top: 58%;
        }
}

@media all and (max-width:768px) {
    .About-Content {
        top: 55%;
        padding: 0px 50px;
    }

        .About-content-top h2,
        .About-content-bottom h2 {
            font-size: 24px;
        }
    
        .About-content-top p,
        .About-content-bottom p {
            font-size: 18px;
            letter-spacing: 1px;
        }
}


@media all and (max-width:500px){
    .About-Content{
       padding: 0px 30px;
       top: 45%;
       padding-top: 40px;
       margin-top: 30px;
    }

    .About-Us-head{
        left: 30px;
        font-size: var(--title-Font-size);
    }

    .About-content-top h2 ,.About-content-bottom h2{
       font-size: var(--card-headFont-size) !important;
     }

    .About-content-top p ,.About-content-bottom p {
       font-size: var(--Least-font-size);
       letter-spacing: 1px;
     }
}

@media all and (max-width:400px) {
    .About-Content {
        padding: 0px 30px;
        top: 45%;
        padding-top: 40px;
        margin-top: 60px;
    }

}





        