

.Contact-Container{
    padding: 0px 72px;
    padding-top: 80px;
    position: relative;
}

.Contact-Container h1{
    color: var(--brand-font-color);
    font-size: var(--header-font-size);
    font-weight: var(--Primary-font-weight);
    position: relative;
    max-width: 322px;
    margin-bottom: 50px;
    cursor: default;
}

.Contact-Container h1::before {
    content: '';
    width: 60px;
    height: 2px;
    background-color: var(--brand-font-color);
    transition: all 250ms;
    position: absolute;
    bottom: -3px;
    left: 5px;
}

.error{
    color: red;
    margin-top: 4px;
    margin-left: 2px;
}

.Contact-Container h1:hover::before {
    width: 100px;
}

.Contact-us-form h2{
    text-align: center;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.Response{
    color: green;
    text-align: center;
    margin-top: 10px;
}

textarea {
    /* resize: none; */
    border: 1px solid var(--brand-font-color);
    height: 100px;
    border-radius: 3px;
    padding: 5px 5px;
}

.Contact-us-form{
    border-radius: 5px;
    padding: 40px 30px;
    margin: 0 auto;
    margin-top: 40px;
    box-shadow: 0px 0px 3px -1px rgba(0, 0, 0, 0.75);
    width: 100%;
    max-width: 500px;
    display: flex;
    gap: 20px;
    flex-direction: column;
}

.Contact-us-form div{
    display: flex;
    flex-direction: column;
}

.Contact-us-form div input{
    border: 1px solid var(--brand-font-color);
    border-radius: 3px;
    padding: 5px 5px;
}

.Contact-us-form div label{
    color:var(--brand-font-color) ;
    margin-bottom: 6px;
}

.Contact-us-form div input ,textarea:focus{
    outline: 0;
}

.Form-btn{
    background-color: var(--brand-font-color);
    color: white;
    border: none;
    transition: all 250ms;
    padding: 10px;
    margin-top: 20px;
    border-radius: 5px;
}

.Form-btn:hover{
    background-color: #eeb96d;
}

.form-top-para p {
    font-size: var(--secondary-ParaFont-size);
        display: flex;
        gap: 10px;
        margin-top: 20px;
        font-weight: var(--external-font-weight);
}

@media all and (max-width:500px){
    .Contact-Container{
        padding: 0 30px;
        padding-top: 80px;
    }

    .Contact-Container h1{
        font-size: var(--title-Font-size);
        margin-bottom: 30px;
    }

    .form-top-para p {
        font-size: var(--Least-font-size);
    }

    .Contact-us-form{
        padding: 40px 20px;
    }
}