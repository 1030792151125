

.Footer-Container{
    background-color: black;
    color: var(--brand-font-color);
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    height: 350px;
}

.Footer-Container a{
    text-decoration: none;
    color: var(--brand-font-color);
    margin-top: -1px;
}

.Footer-Container * {
    text-align: center;
}