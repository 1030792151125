

.Banner-Wrapper{
    width: 100%;
}

.Header-Container{
    position: relative;
    height: 92vh;
    padding: 20px 72px;
}

.Header-Container-Inside{  
    position: relative;
    max-width: var(--Section-width);
    margin: 0px auto;
    height: 100%; 
}

.Banner-Img-Container{
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0px;
    z-index: -1;
    left: 0px;
}

.Banner-Img{
    width: 100%;
    filter: brightness(60%);
    object-fit: cover;
    height: 100%;
}

.BannerContent{
   position: absolute;
   width: 100%;
   display: flex;
   gap: 20px;
   align-items: center;
   flex-direction: column;
   top: 50%;
   left: 50%;
   transform: translate(-50% ,-50%);
}

.BannerContent h3{
    font-size: var(--primary-font-size);
    letter-spacing: 3px;
    color: var(--brand-font-color);
}

.css-button-rounded--black a {
    text-decoration: none;
    color: var(--brand-font-color);
    font-weight: 400;
}

.css-button-rounded--black {
    min-width: 130px;
    height: 40px;
    padding: 5px 10px;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    outline: none;
    border-radius: 5px;
    border: 2px solid #050505;
    background: #000000;
}

.css-button-rounded--black:hover {
    background: #fff;
    color: #000000
}

.css-button-rounded--black:hover a {
    color: black;
    /* This is just an example; you can apply any style you like */
}



.BannerContent p{
    font-size: var(--secondary-font-size);
    color: white;
}

@media all and (max-width:768px){
    .BannerContent h3 {
            font-size: var(--secondary-font-size);
            letter-spacing: 3px;
            color: var(--brand-font-color);
        }

    .BannerContent p {
        font-size: var(--secondary-ParaFont-size);
        color: white;
    }
}

@media all and (max-width:500px){
    .BannerContent h3 {
            /* text-align: center; */
            font-size: var(--mini-ParaFont-size);
            line-height: 1.5;
        }
    .Header-Container{
        padding: 20px 30px;
    }

        .BannerContent p {
            font-size: var(--Least-font-size);
            color: white;
            font-size: 16px;
            letter-spacing: 1px;
        }

         .css-button-rounded--black{
            font-size: 16px;
            font-weight: 400;
         }
}