

.ServicesTop{
    display: flex;
    align-items: center;
    gap: 50px;
}

.Services-Wrapper{
    padding: 0px 72px;
    padding-top: 80px;
}

.BM-Image-Container img{
    width: 100%;
    border-radius: 60px 5px 60px 5px;
}

.BM-Image-Container{
   max-width: 650px;
}

.Services-Header-Content h1{
    font-size: var(--headerMini-font-size);
    font-weight: var(--Primary-font-weight);
}

.Services-Header-Content p{
    font-size: var(--mini-ParaFont-size);
    font-weight: var(--external-font-weight);
    letter-spacing: 1px;
    margin-top: 30px;
}

.highlight {
    color: var(--brand-font-color);
    font-weight: bold;
    padding: 2px;
}

.Services{
    color:var(--brand-font-color) ;
    font-size: var(--header-font-size);
    font-weight: var(--Primary-font-weight);
    position: relative;
    width: 190px;
    margin-bottom: 40px;
    cursor:default;
}

.Services::before{
    content: '';
    width: 60px;
    height: 2px;
    background-color:var(--brand-font-color) ;
    transition: all 250ms;
    position: absolute;
    bottom: -4px;
    left: 5px;
}

.Services:hover::before{
   width: 100px;
}




@media all and (max-width:1000px){
    .ServicesTop{
        flex-direction: column;
    }
}

@media all and (max-width:500px) {
    .Services-Wrapper {
        padding: 0px 30px;
        padding-top: 80px;
    }

    .Services{
        font-size: var(--title-Font-size);
    }

    .Services-Header-Content h1{
        font-size: var(--card-headFont-size);
    }

    .Services-Header-Content p{
        font-size: var(--Least-font-size);
    }
}