:root {
    --brand-font-color:#ca9953;
    --brand-bg-color: #e6e4e4;
    --primary-text-color: white;
    --Section-width:1380px;
    --Primary-font-weight:600;
    --secondary-font-weight:500;
    --external-font-weight:400;
    --secondary-text-color: gold;
    --external-text-color: rgb(39, 39, 225);
    --header-font-size: 42px;
    --primary-font-size: 38px;
    --headerMini-font-size: 32px;
    --secondary-font-size: 28px;
    --secondary-ParaFont-size: 22px;
    --title-Font-size: 24px;
    --mini-ParaFont-size: 18px;
    --card-headFont-size: 20px;
    --Least-font-size: 16px;
}

