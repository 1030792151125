

.IconContainer img{
    width: 100%;
}


.webIcon{
    font-size: 100px;
    transition: all 500ms;
}

.webIcon:hover{
    transform: translatey(-30px);
}



.Off-Card-Container{
    border-radius: 5px;
    box-shadow: 0px 1px 6px -1px rgba(0, 0, 0, 0.75);
    padding: 20px;
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.Card-Description{
    min-height: 200px;
    text-align: center;
}

.Off-Card-Container h1{
   font-weight: var(--secondary-font-weight); 
    height: 50px;
    text-align: center;
    font-size: 19px;
    margin-top: 20px;
}

.Off-Card-Container p{
    line-height: 1.4;
    /* margin-top: 20px; */
}

@media all and (max-width:768px){
    .Card-Description {
            min-height: auto;
            text-align: center;
        }

    .Off-Card-Container{
        padding: 30px 20px;
    }
}

@media all and (max-width:500px){
    .webIcon {
        font-size: 60px;
        transition: all 500ms;
        }
}