

.Navbar-Container {
    padding: 20px 72px;
    background-color: black;
    position: sticky;
    top: 0px;
    z-index: 9;
    width: 100%;
}

.Navbar{
    display: flex;
    max-width:var(--Section-width);
    margin: 0px auto;
    position: relative;
    justify-content: space-between; 
    align-items: center; 
}

.Navbar ul{
    display: flex;
    padding-left: 0px;
    transition: all 250ms;
    gap: 30px;
    margin-bottom: 0px;
}

.Navbar ul li{
    list-style-type: none;
}

.Navbar ul li a{
    text-decoration: none;
    transition: all 500ms;
    color: var(--brand-font-color); 
}

.Navbar ul li a:hover{
    color: #f59918;
}

/* #e2b069   #e2911d*/

.logo {
    font-family: 'Arial', sans-serif;
    font-size: 28px;
    font-weight: bold;
    color: #333;
    text-transform: uppercase;
    letter-spacing: 5px;
    position: relative;
}


.MenuIcon {
    display: none;
    color: var(--brand-font-color);
}

@media all and (max-width:500px){
    .Navbar-Container{
        padding: 20px 30px;
    }
}


@media all and (max-width:700px){
    .logo{
        font-size: 24px;
    }

        .Navbar ul li {

           width: 100%;

           transition: all 250ms;
           text-align: center;
        }

        .Navbar ul li:hover{
            background-color: #333;
        }

        .Navbar ul li a{
            display: block;

            padding: 15px 0px;
            width: 100%;
        }
 
    .Navbar ul{
        position: absolute;
        display: flex;
        border-radius: 5px;
        flex-direction: column;
        top: 46px;
        z-index: 22;
        left: 0px;
        gap: 0px;
        border: none !important;
        margin: 20px 0px;
        padding: 20px 0px;
        justify-content: center;
        align-items: center;
        background-color: black;
        width: 100%;
        border: 1px solid;
    }

    .MenuIcon{
     display: block;
     cursor: pointer;
     font-size: 34px;
    }
}

@media all and (max-width:768px){
    .logo{
        font-size: 24px;
    }
}

.NavItemHeight{
    display: none !important;
}